import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import {Link} from 'gatsby'

import Topbar from '../../components/topbar'
import Footer from '../../components/footer'
import { APP_URI } from '../../configs';

import troopy from '../../images/troopy-faq.svg'

import link from '../../images/link-icon.svg'
import players from '../../images/group-icon.svg'
import cup from '../../images/cup-outline-icon.svg'
import signup from '../../images/signup-icon.svg'
import clip from '../../images/clip-outline-icon.svg'
import payment from '../../images/payment-icon.svg'
import config from '../../images/config-icon.svg'
import contact from '../../images/contact-icon.svg'

import '../../scss/FAQ.scss'

export default class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidMount() {
    new WOW.WOW({
        live: false,
        offset: 500
    }).init();
  }

  scrollToReference(ref) {
    const node = findDOMNode(ref.current);
    node.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  render(){
    return(
    <div className="FAQ">
      <Topbar handlerNavigation={(ref) => this.scrollToReference(ref)} pageReferences={this.references}/>
      <Helmet>
      </Helmet>


       <section ref={this.references.features} className="FAQ--section">
         <div className="wrapper">
         <h2 className="FAQ--title wow fadeIn" data-wow-offset="200">¿Cómo podemos ayudarte?</h2>
         <ol className="FAQ--list wow fadeIn" data-wow-offset="200">
           <Link to="/wip" className="FAQ--item item--1">
              <img src={link} className="item--image"/>
              <h3 className="item--title">
              Vinculación de cuentas
              </h3>
              <p className="item--text">
              ¿Cómo vinculo mis juegos?<br/>¿Puedo vincular más de un juego?
              </p>
           </Link>
           <Link to="/wip" className="FAQ--item item--2">
              <img src={players} className="item--image"/>
              <h3 className="item--title">
              Troops
              </h3>
              <p className="item--text">
              ¿Puedo ser parte de más de un equipo?<br/>¿Cómo elimino un equipo?
              </p>
           </Link>
           <Link to="/wip" className="FAQ--item item--3">
              <img src={cup} className="item--image"/>
              <h3 className="item--title">
              Troop Cup 21
              </h3>
              <p className="item--text">
              ¿Cómo sé si clasifiqué?<br/>
¿Puedo anotarme a más
de un juego?
              </p>
           </Link>
           <Link to="/wip" className="FAQ--item item--4">
              <img src={signup} className="item--image"/>
              <h3 className="item--title">
              Registro
              </h3>
              <p className="item--text">
              ¿No te llega el mail
de verificación?<br/>
¿Olvidaste tu contraseña?
              </p>
           </Link>
         </ol>
          <ol className="FAQ--list wow fadeIn" data-wow-offset="200">
           <Link to="/wip" className="FAQ--item item--1">
              <img src={clip} className="item--image"/>
              <h3 className="item--title">
              Editor de clips
              </h3>
              <p className="item--text">
              ¿Cómo grabo mis juegos?<br/>
¿Puedo subir videos desde
otra plataformaT?
              </p>
           </Link>
           <Link to="/wip" className="FAQ--item item--2">
              <img src={payment} className="item--image"/>
              <h3 className="item--title">
              Pagos y cancelación
              </h3>
              <p className="item--text">
              ¿Cómo cancelo mi suscripción?<br/>
Política de reembolso
              </p>
           </Link>
           <Link to="/wip" className="FAQ--item item--3">
              <img src={config} className="item--image"/>
              <h3 className="item--title">
              Moderación
              </h3>
              <p className="item--text">
              ¿Alguien incumplió las
normas de la comunidad?<br/>
Reportalo
              </p>
           </Link>
           <Link to="/wip" className="FAQ--item item--4">
              <img src={contact} className="item--image"/>
              <h3 className="item--title">
              Contacto
              </h3>
              <p className="item--text">
              ¿No resolviste tu duda?<br/> Contactanos
              </p>
           </Link>
         </ol>
         
          </div>
          <img className="troopy" src={troopy} />
      </section>

      <Footer/>
    </div>
    );
  }
}
